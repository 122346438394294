export interface User {
    _id: string;
    organizationName: string;
    email: string;
    isHost: boolean;
    enabled: boolean;
    iat: number;
    exp: number;
    lastName?: string;
    firstName?: string;
    workspaceIdRoles?: { [key: string]: string[] };
}

export const ANONYMOUS_USER: User = {
    _id: "",
    organizationName: "",
    email: "",
    isHost: false,
    enabled: false,
    iat: 0,
    exp: 9007199254740992
}