import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'site',
    pathMatch: 'full'
  },
  {
    path: 'site',
    loadChildren: () =>
      import('./site/site.module').then(m => m.SiteModule)
  },
  {
    path: 'guest-login',
    loadChildren: () =>
      import('./pages/guest-login/guest-login.module').then(m => m.GuestLoginModule)
  },
  {
    path: 'guest-set-password',
    loadChildren: () =>
      import('./pages/guest-set-password/guest-set-password.module').then(m => m.GuestSetPasswordModule)
  },
  {
    path: 'host-login',
    loadChildren: () =>
      import('./pages/host-login/host-login.module').then(m => m.HostLoginModule)
  },
  {
    path: 'host-email-confirmation',
    loadChildren: () =>
      import('./pages/host-email-confirmation/host-email-confirmation.module').then(m => m.HostEmailConfirmationModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ AuthGuard ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
